export enum GenderEnum {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
}

export enum VerificationTypesEnum {
	IDENTITY = 'IDENTITY',
	PROFILE_PHOTO = 'PROFILE_PHOTO',
	BIO = 'BIO',
}

export enum UserAccountStatusEnum {
	ACTIVE = 'ACTIVE',
	HIDDEN = 'HIDDEN',
	PAUSED = 'PAUSED',
	DELETED = 'DELETED',
	BANNED = 'BANNED',
}

export enum VerifStatusEnum {
	DENIED = 'DENIED',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export enum AdminUserPermissionsEnum {
	CERTIFICATION = 'CERTIFICATION',
	PHOTO_VERIF = 'PHOTO_VERIF',
	BIO_VERIF = 'BIO_VERIF',
	REPORT = 'REPORT',
	STATS = 'STATS',
	USERS = 'USERS',
	REFERRAL = 'REFERRAL',
	HISTORY = 'HISTORY',
	DUPLICATES = 'DUPLICATES',
	REFERRAL_DELETE = 'REFERRAL_DELETE',
	MAINTENANCE_SWITCH = 'MAINTENANCE_SWITCH',
	PROFILE_DETAIL_ACTIONS = 'PROFILE_DETAIL_ACTIONS',
}

export enum AdminUpdateActionEnum {
	WARN = 'WARN',
	REMOVE_BIO = 'REMOVE_BIO',
	CHANGE_GENDER = 'CHANGE_GENDER',
	REQUEST_CERTIF = 'REQUEST_CERTIF',
	REMOVE_CERTIFICATION = 'REMOVE_CERTIFICATION',
}

export enum BanTypeEnum {
	DEVICE_BAN = 'DEVICE_BAN',
	EMAIL_BAN = 'EMAIL_BAN',
}

export enum PhotoGridPositionsEnum {
	CERTIF = 'certif',
	CENTER = 'center',
	RIGHT1 = 'right1',
	RIGHT2 = 'right2',
	BOTTOM1 = 'bottom1',
	BOTTOM2 = 'bottom2',
	BOTTOM3 = 'bottom3',
}
