import { HTMLProps } from 'react';
import { Card, CardHeader } from '@/components/ui/card.tsx';

interface Interface {
	userId: string;
	onclick: () => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function SimpleUserIdItem({ userId, onclick, className }: Interface) {
	return (
		<Card onClick={onclick} className={`w-[370px] ${className}`}>
			<CardHeader className={'p-4'}>
				<span className={'cursor-pointer'}>{userId}</span>
			</CardHeader>
		</Card>
	);
}
