import { Label } from '@/components/ui/label.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useEffect, useState } from 'react';
import { GenderEnum } from '@/lib/enum.ts';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select.tsx';
import ReferralUserItem from '@/components/custom/ReferralUserItem.tsx';
import { getGenderString, isEmptyString } from '@/lib/utils.ts';
import useNotification from '@/components/hooks/useNotification.tsx';
import {
	useCreateReferralUser,
	useDeleteReferralUser,
	useGetReferralUsers,
} from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import { useSearchParams } from 'react-router-dom';
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';
import { IReferralUsers } from '@/lib/interface.ts';
import PageHeadline from '@/components/custom/PageHeadline.tsx';

export default function ReferralPage() {
	const showNotification = useNotification();
	const [searchParams, setSearchParams] = useSearchParams();
	const searchPage = (searchParams.get('page') as string) ?? '1';
	const [page, setPage] = useState(parseInt(searchPage) || 1);
	const { trigger: deleteReferralUser } = useDeleteReferralUser();
	const { data: referralUsers, isLoading: isLoadingUsers } = useGetReferralUsers(page);
	const { trigger: createReferralUser, isMutating: isLoading } = useCreateReferralUser();
	const [referrerData, setReferrerData] = useState({
		username: '',
		referralCode: '',
		gender: GenderEnum.MALE,
	});
	const [referrals, setReferrals] = useState(referralUsers?.data || []);

	useEffect(() => {
		if (!referralUsers) return;
		setReferrals(referralUsers?.data);
	}, [referralUsers]);

	const handleBtnPress = async () => {
		if (
			isEmptyString(referrerData.referralCode) ||
			isEmptyString(referrerData.username) ||
			referrerData.referralCode.length !== 6
		) {
			showNotification('Veuillez remplir tous les champs', 'error');
			return;
		}
		/* create the referrer */
		await createReferralUser({ ...referrerData, username: referrerData.username.trim() });
		setReferrerData({
			username: '',
			referralCode: '',
			gender: GenderEnum.MALE,
		});
		showNotification("L'influenceur a été créé avec succès.");
	};

	const updateSearchParam = (key: string, value: string) => {
		const params = new URLSearchParams(searchParams);
		params.set(key, value);
		setSearchParams(params, { replace: true });
	};

	const onDeletePress = async (user: IReferralUsers) => {
		const yes = await confirm(`Voulez-vous vraiment supprimer ${user.username} ?`);
		if (!yes) return;
		/* remove from state */
		setReferrals(prev => {
			return prev.filter(item => item._id !== user._id);
		});
		/* remove from api */
		await deleteReferralUser({ userId: user._id });
	};

	useEffect(() => {
		if (!page) return;
		updateSearchParam('page', String(page));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<div className={'md:container mb-4 gap-y-4 '}>
			<PageHeadline
				title={'Parrainage'}
				description={'Créez des codes parrainage pour nos influenceurs'}
			/>
			<div className={'flex flex-col items-center px-3 md:px-0'}>
				{/* Inputs */}
				<div className={'flex justify-center items-end gap-3 flex-wrap mb-3'}>
					{/* Name */}
					<div className={'w-full md:w-auto max-w-[500px]'}>
						<Label htmlFor="name">Nom</Label>
						<Input
							id="name"
							maxLength={30}
							type={'search'}
							value={referrerData.username}
							placeholder={"Nom de l'influenceur"}
							onChange={e => {
								setReferrerData(prev => {
									return { ...prev, username: e.target.value };
								});
							}}
						/>
					</div>
					<div className={'w-full md:w-auto flex flex-row items-center gap-3'}>
						{/* code */}
						<div className={'w-full md:max-w-[170px]'}>
							<Label htmlFor="name">Code</Label>
							<Input
								maxLength={6}
								type={'search'}
								id="referralCode"
								value={referrerData.referralCode}
								placeholder={'Code à 6 caractères'}
								onChange={e => {
									setReferrerData(prev => {
										return { ...prev, referralCode: e.target.value.toUpperCase() };
									});
								}}
							/>
						</div>
						{/* Gender */}
						<div className={'w-fit'}>
							<Label htmlFor="name">Sexe</Label>
							<Select
								value={referrerData.gender}
								defaultValue={GenderEnum.MALE}
								onValueChange={(value: GenderEnum) => {
									setReferrerData(prev => {
										return { ...prev, gender: value };
									});
								}}
							>
								<SelectTrigger className="w-[150px] md:w-[130px]">
									<SelectValue placeholder="Theme" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value={GenderEnum.MALE}>
										{getGenderString(GenderEnum.MALE)}
									</SelectItem>
									<SelectItem value={GenderEnum.FEMALE}>
										{getGenderString(GenderEnum.FEMALE)}
									</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</div>
					{/* Button */}
					<Button
						disabled={isLoading}
						onClick={handleBtnPress}
						className={'mt-3 w-full md:w-[110px]'}
					>
						Créer {isLoading && <Loader />}
					</Button>
				</div>

				{/* List of influencers */}
				<div className={'md:container w-full flex flex-col gap-4'}>
					{isLoadingUsers ? (
						<Loader text={'Chargement des influenceurs'} />
					) : referrals?.length === 0 ? (
						<NoDataText text={'Aucun influenceur trouvé'} />
					) : (
						referrals?.map(user => {
							return (
								<ReferralUserItem
									user={user}
									key={user._id}
									onDeletePress={() => onDeletePress(user)}
								/>
							);
						})
					)}
				</div>

				{/* Pagination */}
				{!isLoadingUsers && referralUsers && referralUsers?.pagination?.itemCount > 10 && (
					<Pagination>
						<PaginationContent>
							<PaginationItem>
								<PaginationPrevious
									onClick={() => {
										setPage(prev => Math.max(prev - 1, 1));
									}}
								/>
							</PaginationItem>
							<PaginationItem>
								<PaginationNext
									onClick={() => {
										setPage(prev =>
											Math.min(prev + 1, referralUsers!.pagination.pageCount),
										);
									}}
								/>
							</PaginationItem>
						</PaginationContent>
					</Pagination>
				)}
			</div>
		</div>
	);
}
