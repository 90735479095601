import Grid from '@/components/custom/Grid.tsx';
import { useDuplicateAccounts } from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { useEffect, useState } from 'react';
import SimpleUserIdItem from '@/components/custom/SimpleUserIdItem.tsx';
import routes from '@/lib/routes.tsx';
import { useNavigate } from 'react-router-dom';
import PageHeadline from '@/components/custom/PageHeadline.tsx';

export default function DuplicatesPage() {
	const { data: duplicateUserIds, isLoading: isLoadingDuplicateUserIds } =
		useDuplicateAccounts();
	const [duplicateAccounts, setDuplicateAccounts] = useState<string[]>([]);
	const navigate = useNavigate();

	const goToSubAccounts = (userId: string) => {
		navigate(`${routes.nav.profileDetails}/${userId}/${routes.nav.subAccounts}`);
	};

	useEffect(() => {
		if (!duplicateUserIds) return;
		setDuplicateAccounts(duplicateUserIds);
	}, [duplicateUserIds]);

	return (
		<div className={'md:container mb-4 '}>
			<PageHeadline
				title={'Doublons'}
				description={'Voici la liste des utilisateurs qui ont au moins 2 comptes.'}
			/>
			<div className={'flex flex-col items-center'}>
				{/* Certifications */}
				<Grid className={'gap-5'}>
					{isLoadingDuplicateUserIds ? (
						<Loader text={'Chargement des comptes en double...'} />
					) : duplicateAccounts.length > 0 ? (
						duplicateAccounts.map((userId, index) => (
							<SimpleUserIdItem
								key={index}
								userId={userId}
								onclick={() => goToSubAccounts(userId)}
							/>
						))
					) : (
						<NoDataText text={'Aucun compte double trouvé'} />
					)}
				</Grid>
			</div>
		</div>
	);
}
