import Grid from '@/components/custom/Grid.tsx';
import { useBioVerification, useVerifyBio } from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import BioVerificationItem from '@/components/custom/BioVerificationItem.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { useEffect, useState } from 'react';
import { IBioVerification } from '@/lib/interface.ts';
import PageHeadline from '@/components/custom/PageHeadline.tsx';

export default function BioReviewPage() {
	const { data: bioVerifications, isLoading: isLoadingBioVerifications } =
		useBioVerification();
	const [verifications, setVerifications] = useState<IBioVerification[]>([]);
	const { trigger: verifyBio, isMutating: isLoading } = useVerifyBio();

	const onclickBtn = async (verificationId: string, accept: boolean) => {
		setVerifications(prevState =>
			prevState.filter((item: any) => item._id !== verificationId),
		);
		await verifyBio({ accept, verificationId });
	};

	useEffect(() => {
		if (!bioVerifications) return;
		setVerifications(bioVerifications);
	}, [bioVerifications]);

	return (
		<div className={'md:container mb-4 gap-y-4'}>
			<PageHeadline
				title={'Bio'}
				description={'Voici les BIOs des utilisateurs qui ont été jugées problématiques.'}
			/>
			<div className={'flex flex-col items-center'}>
				{/* Certifications */}
				<Grid className={'gap-5'}>
					{isLoadingBioVerifications ? (
						<Loader text={'Chargement des verifications de bio...'} />
					) : verifications.length > 0 ? (
						verifications.map((verif, index) => (
							<BioVerificationItem
								key={index}
								item={verif}
								isLoading={isLoading}
								onclickBtn={onclickBtn}
							/>
						))
					) : (
						<NoDataText text={'Aucune verification disponible'} />
					)}
				</Grid>
			</div>
		</div>
	);
}
