import { HTMLProps } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';

interface Interface {
	title: string;
	description: string;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function PageHeadline({ title, description, className }: Interface) {
	return (
		<Card className={`my-4 ${className}`}>
			<CardHeader className={'items-center'}>
				<CardTitle>{title}</CardTitle>
				<CardDescription className={'text-center'}>{description}</CardDescription>
			</CardHeader>
		</Card>
	);
}
